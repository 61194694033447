<mat-dialog-content>
	<div class="modal" id="modal">
		<h1 class="font-bold text-lg">{{ 'modal.cookies.title' | translate }}</h1>

		<!-- <ng-container *ngIf="fase === 1">
			<div>
				<p>
					{{ 'modal.cookies.tex1.part1' | translate }}
					<a (click)="goToCookiesPolicy()" target="_blank" class="btn btn-help cursor-pointer">{{
						'modal.cookies.generalInfo.text3' | translate
					}}</a
					>.
					{{ 'modal.cookies.tex1.part3' | translate }}
				</p>
				<p>{{ 'modal.cookies.tex2' | translate }}</p>
			</div>
		</ng-container> -->

		<ng-container *ngIf="fase === 2">
			<div class="content-menu" [formGroup]="cookieModalForm">
				<div class="section-menu">
					<button class="btn" [class.active]="menu === 1" (click)="goToMenu(1)">
						{{ 'modal.cookies.generalInfo.title' | translate }}
					</button>
					<button class="btn" [class.active]="menu === 2" (click)="goToMenu(2)">
						{{ 'modal.cookies.ad.title' | translate }}
					</button>
					<button class="btn" [class.active]="menu === 3" (click)="goToMenu(3)">
						{{ 'modal.cookies.analytics.title' | translate }}
					</button>
					<button class="btn" [class.active]="menu === 4" (click)="goToMenu(4)">
						{{ 'modal.cookies.customization.title' | translate }}
					</button>
					<button class="btn" [class.active]="menu === 5" (click)="goToMenu(5)">
						{{ 'modal.cookies.functionality.title' | translate }}
					</button>
					<button class="btn" [class.active]="menu === 6" (click)="goToMenu(6)">
						{{ 'modal.cookies.security.title' | translate }}
					</button>
					<button class="btn" [class.active]="menu === 7" (click)="goToMenu(7)">
						{{ 'modal.cookies.technicalCookies.title' | translate }}
					</button>
					<!--   <button class="btn" [class.active]="menu == 4" (click)="menu = 4">
						{{'modal.cookies.menu.AdvertisingAndSocial' |translate}}
					</button> -->
				</div>

				<div class="section-content" id="section-content">
					<ng-container *ngIf="menu === 1">
						<div class="height-[100px]">
							<div class="header mb-2">
								<h2 class="font-600">{{ 'modal.cookies.generalInfo.title' | translate }}</h2>
							</div>
							<p class="mb-2">
								{{ 'modal.cookies.generalInfo.text1.part1' | translate }}
							</p>
							<p class="mb-2">
								{{ 'modal.cookies.generalInfo.text1.part2' | translate }}
							</p>
							<p class="mb-2">
								{{ 'modal.cookies.generalInfo.text1.part3' | translate }}
							</p>
							<p class="mb-2">
								{{ 'modal.cookies.generalInfo.text1.part4' | translate }}
							</p>
							<p>
								{{ 'modal.cookies.generalInfo.text2' | translate }}
								<a
									(click)="goToCookiesPolicy()"
									target="_blank"
									class="btn btn-help cursor-pointer"
								>
									{{ 'modal.cookies.generalInfo.text3' | translate }}
								</a>
							</p>
						</div>
					</ng-container>

					<ng-container *ngIf="menu === 2">
						<div class="height-[100px]">
							<div class="header mb-2 flex flex-wrap gap-[5px]">
								<h2 class="font-600">{{ 'modal.cookies.ad.title' | translate }}</h2>

								<mat-slide-toggle
									checked="true"
									formControlName="advertising"
									(change)="addAdvertisingConsent($event)"
								></mat-slide-toggle>
							</div>

							<p class="mb-2">
								{{ 'modal.cookies.ad.text1' | translate }}
							</p>

							<p class="mb-2">
								{{ 'modal.cookies.ad.text2' | translate }}
							</p>

							<p>
								{{ 'modal.cookies.ad.text3' | translate }}
							</p>
						</div>
					</ng-container>

					<ng-container *ngIf="menu === 3">
						<div class="height-[100px]">
							<div class="header mb-2 flex flex-wrap gap-[5px]">
								<h2 class="font-600">{{ 'modal.cookies.analytics.title' | translate }}</h2>
								<mat-slide-toggle
									checked="true"
									formControlName="analytics_storage"
								></mat-slide-toggle>
							</div>

							<p>
								{{ 'modal.cookies.analytics.text1' | translate }}
							</p>
						</div>
					</ng-container>

					<ng-container *ngIf="menu === 4">
						<div class="height-[100px]">
							<div class="header mb-2 flex flex-wrap gap-[5px]">
								<h2 class="font-600">{{ 'modal.cookies.customization.title' | translate }}</h2>
								<mat-slide-toggle
									checked="true"
									formControlName="personalization_storage"
								></mat-slide-toggle>
							</div>

							<p>
								{{ 'modal.cookies.customization.text1' | translate }}
							</p>
						</div>
					</ng-container>

					<ng-container *ngIf="menu === 5">
						<div class="height-[100px]">
							<div class="header mb-2 flex flex-wrap gap-[5px]">
								<h2 class="font-600">{{ 'modal.cookies.functionality.title' | translate }}</h2>
								<mat-slide-toggle
									checked="true"
									formControlName="functionality_storage"
								></mat-slide-toggle>
							</div>
							<p>
								{{ 'modal.cookies.functionality.text1' | translate }}
							</p>
						</div>
					</ng-container>

					<ng-container *ngIf="menu === 6">
						<div class="height-[100px]">
							<div class="header mb-2 flex flex-wrap gap-[5px]">
								<h2 class="font-600">{{ 'modal.cookies.security.title' | translate }}</h2>
								<span class="text-red-1">{{ 'modal.cookies.alwaysActive' | translate }}</span>
							</div>
							<div>
								<p>
									{{ 'modal.cookies.security.text1' | translate }}
								</p>
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="menu === 7">
						<div class="height-[100px]">
							<div class="header mb-2 flex flex-wrap gap-[5px]">
								<h2 class="font-600">{{ 'modal.cookies.technicalCookies.title' | translate }}</h2>
								<span class="text-red-1">{{ 'modal.cookies.alwaysActive' | translate }}</span>
							</div>
							<div>
								<p>
									{{ 'modal.cookies.technicalCookies.text1' | translate }}
								</p>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</ng-container>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<div class="footer">
		<button class="button-secondary flex-auto" *ngIf="fase === 1" (click)="fase = 2">
			{{ 'modal.cookies.customCookies' | translate }}
		</button>
		<button class="button-secondary flex-auto" *ngIf="fase === 2" (click)="acceptCookies()">
			{{ 'modal.cookies.selectedCookies' | translate }}
		</button>
		<button class="button-secondary flex-auto" (click)="rejectAllCookies()">
			{{ 'modal.cookies.rejectAllCookies' | translate }}
		</button>
		<button class="button-secondary flex-auto" (click)="acceptAllCookies()">
			{{ 'modal.cookies.allowAllCookies' | translate }}
		</button>
	</div>
</mat-dialog-actions>
