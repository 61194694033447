<div style="position: sticky; z-index: 11">
	@if ((isCookiesShown$ | async) === false) {
		<div #cookieContent class="mat-elevation-z6 cookie-content md:w-[95%]">
			{{ 'modal.cookies.tex1.part1' | translate }}
			<a
				(click)="goToCookiesPolicy()"
				target="_blank"
				class="text-red-500 underline cursor-pointer"
				>{{ 'modal.cookies.generalInfo.text3' | translate }}</a
			>.
			{{ 'modal.cookies.tex1.part3' | translate }}

			<div class="footer">
				<button class="button-secondary flex-auto" (click)="openConfigCookiesModal()">
					{{ 'modal.cookies.customCookies' | translate }}
				</button>
				<button class="button-secondary flex-auto" (click)="rejectAllCookies()">
					{{ 'modal.cookies.rejectAllCookies' | translate }}
				</button>
				<button class="button-secondary flex-auto" (click)="acceptAllCookies()">
					{{ 'modal.cookies.allowAllCookies' | translate }}
				</button>
			</div>
		</div>
	}
</div>
