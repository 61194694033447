import {
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
	signal
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '@core/auth/service/auth.service';
import { Subject, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { SelectTruckModalComponent } from '@shared/components/select-truck-modal/select-truck-modal.component';
import { LangService } from '@shared/services/lang.service';
import { LanguagesComponent } from '../languages/languages.component';
import { environment } from '@env/environment';
import { CookieService } from '@shared/services/cookies.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [TranslateModule, RouterLink, NgIf, MatIconModule, LanguagesComponent]
})
export class FooterComponent implements OnInit, OnDestroy {
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	public readonly isAuth = signal<boolean>(false);
	public isDomainEs = signal<boolean>(false);
	public facebookLink = signal<string>('https://www.facebook.com/autosmotos.es');

	public roDomain = signal<string>(environment.roWebsite);
	public itDomain = signal<string>(environment.itWebsite);
	public deDomain = signal<string>(environment.deWebsite);
	public esDomain = signal<string>(environment.esWebsite);

	constructor(
		private dialog: MatDialog,
		private _authService: AuthService,
		private readonly _langService: LangService,
		private readonly _cookieService: CookieService
	) {}

	ngOnInit(): void {
		this._langService.currentDomain
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((value) => this.isDomainEs.set(value === 'es'));

		this._langService.currentLang
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(() => this.facebookLink.set(this._langService.getFacebookPage()));

		this._authService.isAuthenticated$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isAuth) => {
			this.isAuth.set(isAuth);
		});
	}

	openCookieModal(): void {
		this._cookieService.setCookieShown(false);
	}

	openTruckTypeModal(): void {
		this.dialog.open(SelectTruckModalComponent, {
			maxWidth: '1000px',
			maxHeight: 'max-content',
			height: 'max-content',
			width: '100%',
			panelClass: 'full-screen-modal'
		});
	}

	openPlayStore(): void {
		const url = 'https://play.google.com/store/apps/details?id=es.autosmotos.app';
		window.open(url, '_blank');
	}

	openIosStore(): void {
		const url = 'https://apps.apple.com/pe/app/autosmotos/id6470079257';
		window.open(url, '_blank');
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
}
