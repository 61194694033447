<div class="flex flex-col mx-auto flex-auto w-full min-w-0 max-w-[1600px]">
	<!-- App banner -->
	@if (showBanner()) {
		<app-store-banner-mobile class="sticky top-0 z-50 lg:hidden" (hideBanner)="hideAppBanner()" />
	}

	<!-- Header -->
	<app-header />

	<!-- Content -->
	<div class="flex flex-col flex-auto min-h-screen">
		<!-- Cookie modal -->
		@if (isCookieModalCalled()) {
			<app-cookies></app-cookies>
		}

		<router-outlet></router-outlet>
	</div>

	<!-- Footer -->
	<app-footer></app-footer>
</div>
