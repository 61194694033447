<div class="flex items-center h-16 whitespace-nowrap shadow-sm bg-white gap-0 xs:gap-1 p-1 sm:p-4">
	<!-- menu button for mobile -->
	<div class="flex xl:hidden xs:w-12">
		@if (isScreenSmall()) {
			<app-header-wrapped-menu />
		}
	</div>

	<!-- spacer -->
	<span class="flex-1 xl:hidden"></span>

	<!-- Logo -->
	<a class="max-w-64 w-full md:w-64" routerLink="/">
		<!-- <app-logo /> -->
		<img
			class="w-full"
			src="/assets/images/logo.svg"
			alt="logo"
			width="162"
			height="21"
			loading="eager"
		/>
	</a>

	<!-- spacer -->
	<span class="flex-1"></span>

	<!-- menu button for desktop -->
	<div class="xl:flex hidden">
		@if (!isScreenSmall()) {
			<app-header-menu />
		}
	</div>

	@if (isAuth()) {
		@defer (when isAuth()) {
			<app-user />
		}
	}

	<!-- Language button for desktop -->
	<div class="xl:flex hidden">
		@if (!isScreenSmall()) {
			<app-languages />
		}
	</div>
</div>
