<footer class="footer-content">
	<div class="footer-row">
		<div class="footer-column">
			<img
				src="assets/images/logo-white.svg"
				class="logo"
				width="186"
				height="28"
				alt="AutosMotos-logo"
				loading="lazy"
			/>
			<div class="contact-group">
				<!-- Facebook -->
				<a [href]="facebookLink()">
					<img
						src="assets/images/facebook.svg"
						width="36"
						height="36"
						loading="lazy"
						alt="facebook link"
					/>
				</a>

				<!-- Instagram -->
				<a href="https://www.instagram.com/autosmotos.es/">
					<img
						src="assets/images/instagram.svg"
						width="36"
						height="36"
						loading="lazy"
						alt="instagram link"
					/>
				</a>

				<!-- Linkedin -->
				<a href="https://www.linkedin.com/company/autosmotos/">
					<img
						src="assets/images/linkedin.svg"
						width="36"
						height="36"
						loading="lazy"
						alt="linkedin link"
					/>
				</a>

				<!-- Email -->
				<a href="mailto:info@autosmotos.es">
					<img
						src="assets/images/email.svg"
						width="36"
						height="36"
						loading="lazy"
						alt="email link"
					/>
				</a>
			</div>
		</div>

		<div class="footer-column">
			<p class="text-lg font-semibold">{{ 'layout.footer.popular' | translate }}</p>

			<a class="footer-link" routerLink="/coches-segunda-mano">
				{{ 'layout.footer.popularAuto' | translate }}
			</a>

			<a class="footer-link" routerLink="/motos-segunda-mano">
				{{ 'layout.footer.popularMoto' | translate }}
			</a>

			<a class="footer-link" routerLink="/autocaravanas-segunda-mano">
				{{ 'layout.footer.popularMobileHome' | translate }}
			</a>

			<a class="footer-link" routerLink="/camiones-segunda-mano">
				{{ 'layout.footer.popularTruck' | translate }}
			</a>
		</div>

		<!-- <div class="footer-column">
			<p class="text-lg font-semibold">{{ 'layout.footer.rentalAndSell' | translate }}</p>

			<a [routerLink]="isAuth() ? '/sale/create/auto' : '/auth/sign'" class="footer-link">
				{{ 'layout.footer.sellAuto' | translate }}
			</a>

			<a [routerLink]="isAuth() ? '/sale/create/bike' : '/auth/sign'" class="footer-link">
				{{ 'layout.footer.sellBike' | translate }}
			</a>

			<a [routerLink]="isAuth() ? '/sale/create/mobile-home' : '/auth/sign'" class="footer-link">{{
				'layout.footer.sellMobileHome' | translate
			}}</a>

			<a *ngIf="isAuth()" (click)="openTruckTypeModal()" class="footer-link">
				{{ 'layout.footer.sellTruck' | translate }}
			</a>

			<a *ngIf="!isAuth()" routerLink="/auth/sign" class="footer-link">
				{{ 'layout.footer.sellTruck' | translate }}
			</a>
		</div> -->

		<div class="footer-column">
			<p class="text-lg font-semibold">{{ 'layout.footer.legal' | translate }}</p>

			<a class="footer-link" routerLink="/legals/cookies-policy">
				{{ 'layout.footer.cookies' | translate }}
			</a>

			<a class="footer-link" routerLink="/legals/terms-and-conditions">
				{{ 'layout.footer.terms' | translate }}
			</a>

			<a class="footer-link" routerLink="/legals/privacy-policy">
				{{ 'layout.footer.policy' | translate }}
			</a>

			<a class="footer-link" (click)="openCookieModal()">
				{{ 'layout.footer.manageCookies' | translate }}
			</a>
		</div>

		@if (isDomainEs()) {
			<div class="footer-column">
				<p class="text-lg font-semibold">{{ 'layout.footer.aboutUs' | translate }}</p>

				<a class="footer-link" href="https://autosmotos.es/blog">
					{{ 'layout.footer.ourBlog' | translate }}
				</a>
			</div>
		}
	</div>
	<div class="flex flex-col items-center gap-2">
		<span class="font-bold">{{ 'layout.footer.discoverApp' | translate }}</span>
		<div class="flex gap-2 flex-wrap">
			<img
				class="cursor-pointer h-6"
				src="/assets/images/banner/footer/play_store_footer.svg"
				alt="play-store-logo"
				width="96"
				height="24"
				loading="lazy"
				(click)="openPlayStore()"
			/>
			<img
				class="cursor-pointer h-6"
				src="/assets/images/banner/footer/ios_store_footer.svg"
				alt="app-store-logo"
				width="96"
				height="24"
				loading="lazy"
				(click)="openIosStore()"
			/>
		</div>
	</div>
	<div class="footer-row">
		<div class="footer-bottom">
			<div class="content-language">
				<!-- <app-languages></app-languages> -->
				<a class="footer-link" [href]="esDomain()"> {{ 'globals.lang.es' | translate }} </a>
				<a class="footer-link" [href]="itDomain()"> {{ 'globals.lang.it' | translate }} </a>
				<a class="footer-link" [href]="deDomain()"> {{ 'globals.lang.de' | translate }} </a>
				<a class="footer-link" [href]="roDomain()"> {{ 'globals.lang.ro' | translate }} </a>
				<!-- <a class="footer-link " (click)="changeLang('en')">{{ 'globals.lang.en' | translate }}</a> -->
			</div>

			<p>{{ 'layout.footer.allRightsReserved' | translate }}</p>
		</div>
	</div>
</footer>
