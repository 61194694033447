import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';
import { MediaWatcherService } from './media-watcher.service';

export const provideMediaWatcher = (): Array<Provider | EnvironmentProviders> => {
	return [
		{
			provide: ENVIRONMENT_INITIALIZER,
			useValue: () => inject(MediaWatcherService),
			multi: true
		}
	];
};
