import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class IconsService {
	/**
	 * Constructor
	 */
	constructor() {
		const domSanitizer = inject(DomSanitizer);
		const matIconRegistry = inject(MatIconRegistry);

		// Register icon sets
		matIconRegistry.addSvgIconSet(
			domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/autosmotos.svg')
		);
		matIconRegistry.addSvgIconSetInNamespace(
			'flags',
			domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/flags.svg')
		);
		matIconRegistry.addSvgIconSetInNamespace(
			'mat_outline',
			domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg')
		);
		matIconRegistry.addSvgIconSetInNamespace(
			'mat_solid',
			domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg')
		);
		matIconRegistry.addSvgIconSetInNamespace(
			'heroicons_outline',
			domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg')
		);
		matIconRegistry.addSvgIconSetInNamespace(
			'heroicons_solid',
			domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg')
		);
	}
}
