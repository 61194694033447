import { Component, HostListener, Inject, OnInit, signal } from '@angular/core';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { TabTitleService } from '@shared/services/tab-title.service';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationService } from '@shared/services/navigation.service';
import { DOCUMENT } from '@angular/common';
import { ConfigGaService } from '@shared/services/config-ga.service';
import { CookiesComponent } from './components/cookies/cookies.component';
import { StoreBannerMobileComponent } from './components/store-banner-mobile/store-banner-mobile.component';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { filter, map } from 'rxjs';

@Component({
	selector: 'app-layouts',
	templateUrl: './layouts.component.html',
	// encapsulation: ViewEncapsulation.None,
	// changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [TabTitleService],
	standalone: true,
	imports: [
		RouterOutlet,
		FooterComponent,
		HeaderComponent,
		TranslateModule,
		CookiesComponent,
		StoreBannerMobileComponent
	]
})
export class LayoutsComponent implements OnInit {
	public isCookieModalCalled = signal<boolean>(false);
	public showBanner = signal<boolean>(false);

	constructor(
		private navigationService: NavigationService,
		private readonly _configGaService: ConfigGaService,
		@Inject(DOCUMENT) private document: any,
		private _cookieService: SsrCookieService,
		private _router: Router
	) {}

	ngOnInit(): void {
		this._configGaService.checkConsentConfig();

		this.navigationService.initNavigationService();

		this._router.events
			.pipe(
				filter((event) => event instanceof NavigationStart),
				map((event) => event as NavigationStart)
			)
			.subscribe((event: NavigationStart) => {
				const isHome = event.url === '/';
				const isAppBannerHide = this._cookieService.get('isAppBannerHide') === 'true';
				this.showBanner.set(isHome && !isAppBannerHide);
			});
	}

	hideAppBanner(): void {
		this.showBanner.set(false);
	}

	@HostListener('window:scroll')
	openCookieModal(): void {
		if (this.isCookieModalCalled()) {
			return;
		}

		const scrollPosition =
			this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
		const scrollThreshold = 200;

		if (scrollPosition > scrollThreshold) {
			this.isCookieModalCalled.set(true);
		}
	}
}
