import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Output,
	ViewEncapsulation
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Component({
	selector: 'app-store-banner-mobile',
	standalone: true,
	imports: [TranslateModule, MatButtonModule, MatIconModule],
	templateUrl: './store-banner-mobile.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreBannerMobileComponent {
	@Output() hideBanner: EventEmitter<void> = new EventEmitter();

	constructor(private _cookieService: SsrCookieService) {}

	hideAppBanner(): void {
		this._cookieService.set('isAppBannerHide', 'true', undefined, '/');

		this.hideBanner.emit();
	}

	downloadApp(): void {
		const userAgent = navigator.userAgent;
		let url = '';

		if (/Android/i.test(userAgent)) {
			url = 'https://play.google.com/store/apps/details?id=es.autosmotos.app';
		} else if (/iPhone|iPad|iPod/i.test(userAgent)) {
			url = 'https://apps.apple.com/pe/app/autosmotos/id6470079257';
		} else {
			alert('La aplicación no está disponible para su dispositivo.');
			return;
		}

		window.open(url, '_blank');
	}
}
