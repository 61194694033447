import { NgOptimizedImage, NgTemplateOutlet, UpperCasePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	OnInit,
	ViewEncapsulation,
	signal
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { environment } from '@env/environment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LangService } from '@shared/services/lang.service';

@Component({
	selector: 'app-languages',
	templateUrl: './languages.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'languages',
	standalone: true,
	imports: [
		MatIconModule,
		MatMenuModule,
		NgOptimizedImage,
		NgTemplateOutlet,
		TranslateModule,
		UpperCasePipe
	]
})
export class LanguagesComponent implements OnInit {
	public readonly activeLang = signal<string>('es');
	public readonly availableLangs = signal<string[]>(['es', 'de', 'it', 'ro']);

	public roDomain: string = environment.roWebsite;
	public itDomain: string = environment.itWebsite;
	public deDomain: string = environment.deWebsite;
	public esDomain: string = environment.esWebsite;

	/**
	 * Constructor
	 */
	constructor(
		private readonly _translateService: TranslateService,
		private _langService: LangService
	) {}

	// -----------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Subscribe to language changes
		this._translateService.onLangChange.subscribe((change) => {
			// Get the active lang
			this.activeLang.set(change.lang);
		});
	}

	// -----------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------

	/**
	 * Set the active lang
	 *
	 * @param lang
	 */
	setActiveLang(lang: string): void {
		// Set the active lang
		this._langService.setLang(lang);
		// this._translateService.use(lang);
	}

	/**
	 * Get the href for an anchor tag based on the language.
	 * @param lang The language for which to retrieve the href.
	 * It should be one of the following: 'ro', 'it', 'de', 'es'.
	 * @returns The corresponding href for the specified language.
	 * If the language is not valid, '#' is returned as the default href.
	 */
	getLangUrl(lang: string): string {
		let redirectUrl: string;

		switch (lang) {
			case 'ro':
				redirectUrl = this.roDomain;
				break;
			case 'it':
				redirectUrl = this.itDomain;
				break;
			case 'de':
				redirectUrl = this.deDomain;
				break;
			case 'es':
				redirectUrl = this.esDomain;
				break;
			default:
				redirectUrl = '#';
				break;
		}

		return redirectUrl;
	}
}
