import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withInMemoryScrolling, withViewTransitions } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { provideIcons } from '@core/icons/icons.provider';
import { provideMediaWatcher } from '@core/media-watcher/media-watcher.provider';
import { provideAuth } from '@core/auth/auth.provider';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient): any {
	return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

export const provideTranslation = (): any => ({
	defaultLanguage: 'es',
	loader: {
		provide: TranslateLoader,
		useFactory: HttpLoaderFactory,
		deps: [HttpClient]
	}
});

export const appConfig: ApplicationConfig = {
	providers: [
		provideAnimations(),
		provideAuth(),
		provideIcons(),
		provideHttpClient(withFetch()),
		provideClientHydration(),
		// withHttpTransferCacheOptions({
		// 	includePostRequests: true,
		// })
		provideRouter(
			appRoutes,
			withViewTransitions({
				skipInitialTransition: true
			}),
			// withPreloading(PreloadAllModules),
			withInMemoryScrolling({ scrollPositionRestoration: 'top' })
		),
		provideMediaWatcher(),
		importProvidersFrom([
			HttpClientModule,
			TranslateModule.forRoot(provideTranslation()),
			NgxGoogleAnalyticsModule
		])
	]
};
