import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from './service/auth.service';

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (
	req: HttpRequest<unknown>,
	next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
	const authService = inject(AuthService);
	const router = inject(Router);

	// Clone the request object
	let newReq = req.clone();

	// Request
	if (authService.accessToken) {
		newReq = req.clone({
			headers: req.headers.set('Authorization', 'Bearer ' + authService.accessToken)
		});
	}

	// Response
	return next(newReq).pipe(
		catchError((error) => {
			// Catch "401 Unauthorized" responses
			if (error instanceof HttpErrorResponse && error.status === 401) {
				// Sign out
				// authService.signOut();
				// Reload the app
				// location.reload();
			}
			// Catch "403 Forbidden" responses
			if (error instanceof HttpErrorResponse && error.status === 403) {
				// redirection
				// router.navigate(['/403-forbidden-error']);
			}
			// Catch "500 " responses
			if (error instanceof HttpErrorResponse && error.status === 500) {
				// redirection
				router.navigate(['/500-server-error']);
			}

			return throwError(error);
		})
	);
};
