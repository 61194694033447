import { NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ConfigGaService } from '../../services/config-ga.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { BreakpointObserver } from '@angular/cdk/layout';
@Component({
	selector: 'app-modal-cookie',
	templateUrl: './modal-cookie.component.html',
	styleUrls: ['./modal-cookie.component.scss'],
	standalone: true,
	imports: [
		TranslateModule,
		MatDialogModule,
		NgIf,
		ReactiveFormsModule,
		MatSlideToggleModule,
		ReactiveFormsModule,
		MatButtonModule
	]
})
export class ModalCookieComponent implements OnInit {
	fase: number = 1;
	menu: number = 1;

	cookieModalForm: FormGroup = new FormGroup({
		ad_storage: new FormControl(false),
		analytics_storage: new FormControl(false),
		ad_user_data: new FormControl(false),
		ad_personalization: new FormControl(false),
		functionality_storage: new FormControl(false),
		personalization_storage: new FormControl(false),
		advertising: new FormControl(false)
	});

	constructor(
		private readonly _configGaService: ConfigGaService,
		private dialogRef: MatDialogRef<ModalCookieComponent>,
		private readonly router: Router,
		private readonly _cookieService: SsrCookieService,
		private readonly breakpointObserver: BreakpointObserver,
		@Inject(MAT_DIALOG_DATA) private readonly data: any
	) {}

	ngOnInit(): void {
		this.setInitialData();
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event: any) => {
				if (event['url'] === '/legals/cookies-policy') {
					this.closeModal(false);
				}
			});
	}

	setInitialData(): void {
		if (this.data?.fase) {
			this.fase = this.data.fase;
		}
		if (this._cookieService.get('consentConfig')) {
			this.cookieModalForm.patchValue(JSON.parse(this._cookieService.get('consentConfig')));
		}
	}

	goToMenu(value: number): void {
		this.menu = value;
		this.breakpointObserver.observe('(max-width: 660px)').subscribe({
			next: (value) => {
				if (value.breakpoints['(max-width: 660px)']) {
					document
						.querySelector('#section-content')
						?.scrollIntoView({ behavior: 'smooth', block: 'start' });
				} else {
					document.querySelector('#modal')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
				}
			}
		});
	}

	// handleGoogleAnalytics(): void {
	// 	!this.isGoogleAnalyticsActive.value
	// 		? this.configGaService.enableGa()
	// 		: this.configGaService.disableGa();
	// }

	// handleAdsSocialMedia(): void {
	// 	 console.info(!this.isAdsSocialMedia.value);
	// }

	addAdvertisingConsent(value: MatSlideToggleChange): void {
		this.cookieModalForm.patchValue({
			ad_storage: value.checked,
			ad_user_data: value.checked,
			ad_personalization: value.checked
		});
	}

	// acceptCustomCookies(): void {
	// 	this._configGaService.enableConsent(this.cookieModalForm.value);
	// 	this.closeModal(true);
	// }

	acceptAllCookies(): void {
		this.cookieModalForm.patchValue({
			ad_storage: true,
			analytics_storage: true,
			ad_user_data: true,
			ad_personalization: true,
			functionality_storage: true,
			personalization_storage: true,
			advertising: true
		});
		this.acceptCookies();
	}

	rejectAllCookies(): void {
		this.cookieModalForm.patchValue({
			ad_storage: false,
			analytics_storage: false,
			ad_user_data: false,
			ad_personalization: false,
			functionality_storage: false,
			personalization_storage: false,
			advertising: false
		});
		this.rejectCookies();
	}

	acceptCookies(): void {
		this.deleteCookies();
		this._cookieService.set(
			'consentConfig',
			JSON.stringify(this.cookieModalForm.value),
			undefined,
			'/'
		);
		this._configGaService.enableConsent(this.cookieModalForm.value);
		this._cookieService.set('isGaEnabled', 'true', undefined, '/');
		this.closeModal(true);
	}

	rejectCookies(): void {
		this.deleteCookies();
		this._cookieService.set(
			'consentConfig',
			JSON.stringify(this.cookieModalForm.value),
			undefined,
			'/'
		);
		this._configGaService.disableConsent();
		this._cookieService.set('isGaEnabled', 'false', undefined, '/');
		this.closeModal(true);
	}

	deleteCookies(): void {
		this._cookieService.delete('consentConfig');
		this._cookieService.delete('isGaEnabled');
	}

	goToCookiesPolicy(): void {
		this.router.navigate(['/legals/cookies-policy']);
	}

	closeModal(isClosed: boolean): void {
		this.dialogRef.close(isClosed);
	}
}
