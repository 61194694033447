import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LayoutsComponent } from '@layout/layouts.component';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet, LayoutsComponent],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent {
	title = 'AutosMotos';

	constructor() {
		registerLocaleData(localeEs);
	}
}
