<!-- Button -->
<button
	class="flex justify-center items-center p-2 h-9 hover:shadow-lg"
	[matMenuTriggerFor]="languages"
>
	<span class="flex items-center">
		<ng-container *ngTemplateOutlet="flagImage; context: { $implicit: activeLang() }">
		</ng-container>
		<span class="xl:flex hidden font-semibold ml-2">{{ activeLang() | uppercase }}</span>
		<span class="xl:hidden font-semibold">{{ 'globals.lang.' + activeLang() | translate }}</span>
		<mat-icon class="text-red-600" svgIcon="mat_outline:expand_more"></mat-icon>
	</span>
</button>

<!-- Language menu -->
<mat-menu [xPosition]="'before'" #languages="matMenu">
	@for (lang of availableLangs(); track $index) {
		<a mat-menu-item [href]="getLangUrl(lang)">
			<span class="flex items-center">
				<ng-container *ngTemplateOutlet="flagImage; context: { $implicit: lang }"></ng-container>
				<span class="xl:flex hidden font-semibold ml-3">{{ lang | uppercase }}</span>
				<span class="xl:hidden font-semibold">{{ 'globals.lang.' + lang | translate }}</span>
			</span>
		</a>
	}
</mat-menu>

<!-- Flag image template -->
<ng-template let-lang #flagImage>
	<span class="relative w-6 shadow rounded-sm overflow-hidden hidden xl:flex">
		<span class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-10"></span>
		<img
			class="w-full"
			[ngSrc]="'assets/images/flags/' + lang + '.svg'"
			[alt]="'Flag image for ' + lang"
			width="20"
			height="15"
		/>
	</span>
</ng-template>
