import { Injectable } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CookieService {
	constructor(private readonly _ssrCookieService: SsrCookieService) {}

	private isCookiesShown$: BehaviorSubject<boolean> = new BehaviorSubject(
		this._ssrCookieService.get('showCookieModal') === 'false'
	);

	isCookieShown(): Observable<boolean> {
		return this.isCookiesShown$.asObservable();
	}

	setCookieShown(value: boolean): void {
		this.isCookiesShown$.next(value);
	}
}
