import {
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
	afterRender,
	signal
} from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MediaWatcherService } from '@core/media-watcher/media-watcher.service';
import { HeaderWrappedMenuComponent } from '@layout/components/menu/wrapped/menu-wrapped.component';
import { HeaderMenuComponent } from '@layout/components/menu/unwrap/menu.component';
import { UserComponent } from '@layout/components/user/user.component';
import { AuthService } from '@core/auth/service/auth.service';
import { LanguagesComponent } from '../languages/languages.component';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgIf,
		HeaderMenuComponent,
		HeaderWrappedMenuComponent,
		LanguagesComponent,
		MatIconModule,
		RouterLinkWithHref,
		UserComponent
	]
})
export class HeaderComponent implements OnInit, OnDestroy {
	public readonly isScreenSmall = signal<boolean>(false);
	public readonly isAuth = signal<boolean>(false);
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor(
		private _mediaWatcherService: MediaWatcherService,
		private _authService: AuthService
	) {
		afterRender(() => {
			this._mediaWatcherService.onMediaChange$
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe((matchingAliases) => {
					// Check if the screen is small
					this.isScreenSmall.set(!matchingAliases.includes('xl'));
				});
		});
	}

	// -----------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._authService.isAuthenticated$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isAuth) => {
			this.isAuth.set(isAuth);
		});
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
}
