import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { GoogleAnalyticConfig } from '../interfaces/ga-config-interface';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { ConsentCookieConfig } from '@shared/interfaces/consent-cookie-config';

@Injectable({
	providedIn: 'root'
})
export class ConfigGaService {
	isGaEnabled = this._cookieService.get('isGaEnabled');

	constructor(
		private readonly gaService: GoogleAnalyticsService,
		private readonly _cookieService: SsrCookieService
	) {}

	disableConsent(): void {
		this.gaService.gtag('consent', 'update', {
			ad_storage: 'denied',
			analytics_storage: 'denied',
			ad_user_data: 'denied',
			ad_personalization: 'denied',
			functionality_storage: 'denied',
			personalization_storage: 'denied',
			security_storage: 'granted'
		});
	}

	checkConsentConfig(): void {
		if (this._cookieService.get('consentConfig')) {
			const consentConfig = JSON.parse(this._cookieService.get('consentConfig'));
			this.enableConsent(consentConfig);
		}
	}

	enableConsent(config: ConsentCookieConfig): void {
		this.gaService.gtag('consent', 'update', {
			ad_storage: config.ad_storage ? 'granted' : 'denied',
			analytics_storage: config.analytics_storage ? 'granted' : 'denied',
			ad_user_data: config.ad_user_data ? 'granted' : 'denied',
			ad_personalization: config.ad_personalization ? 'granted' : 'denied',
			functionality_storage: config.functionality_storage ? 'granted' : 'denied',
			personalization_storage: config.personalization_storage ? 'granted' : 'denied',
			security_storage: 'granted'
		});
	}

	emitEvent(config: GoogleAnalyticConfig): void {
		// this.gaService.event(config.action, config.category, config.label, config.value);
	}

	enableGa(): void {
		// this.cookieService.set('isGaEnabled', 'true');
		// const isTagsInDom = document.querySelectorAll('script[id=google-analytic]');
		// if (isTagsInDom.length === 0) {
		// 	const head = document.getElementsByTagName('head')[0];
		// 	const body = document.getElementsByTagName('body')[0];
		// 	const scriptTagManager = document.createElement('script');
		// 	const scriptGtag = document.createElement('script');
		// 	const scriptDataLayer = document.createElement('script');
		// 	scriptTagManager.id = 'google-analytic';
		// 	scriptTagManager.async = true;
		// 	scriptTagManager.defer = true;
		// 	scriptTagManager.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalytics.trackId}`;
		// 	scriptDataLayer.id = 'google-analytic';
		// 	// eslint-disable-next-line max-len
		// 	scriptDataLayer.innerHTML = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${environment.googleAnalytics.trackId}')`;
		// 	scriptGtag.id = 'google-analytic';
		// 	scriptGtag.innerHTML =
		// 		// eslint-disable-next-line quotes
		// 		"gtag('event', 'conversion', {'send_to': 'AW-354425156/zXxoCMzfiK8YEMSygKkB'});";
		// 	head.appendChild(scriptTagManager);
		// 	head.appendChild(scriptDataLayer);
		// 	body.appendChild(scriptGtag);
		// }
	}

	disableGa(): void {
		// this.cookieService.set('isGaEnabled', 'false');
		// const gaTags = document.querySelectorAll('script[id=google-analytic]');
		// if (gaTags) {
		// 	gaTags.forEach((tag, index) => {
		// 		gaTags[index].parentNode?.removeChild(gaTags[index]);
		// 	});
		// }
	}
}
