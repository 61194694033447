import {
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
	signal
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { GaClickDirective } from '@shared/directives/ga-click.directive';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '@core/auth/service/auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, UpperCasePipe } from '@angular/common';
import { ModalsAlertComponent } from '@shared/components/modals-alert/modals-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@core/user/user.service';
import { Profile } from '@core/user/user.types';
import { ModalAdsComponent } from '@shared/components/modal-ads/modal-ads.component';

@Component({
	selector: 'app-header-menu',
	templateUrl: './menu.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgIf,
		GaClickDirective,
		MatIconModule,
		MatMenuModule,
		RouterLink,
		RouterLinkActive,
		TranslateModule,
		UpperCasePipe
	]
})
export class HeaderMenuComponent implements OnInit, OnDestroy {
	public isAuth = signal<boolean>(false);
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	user!: Profile;

	constructor(
		private _authService: AuthService,
		private dialog: MatDialog,
		private _userService: UserService
	) {}

	// -----------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------

	ngOnInit(): void {
		this._authService.isAuthenticated$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((isAuth) => this.isAuth.set(isAuth));

		this._userService.user$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((user) => (this.user = user));
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	openModalSelectAds(): void {
		if (this.user && !this.user.email_verified_at) {
			this.openModalNotConfirmEmail();
			return;
		}

		if (this.user && this.user.status != 'APROBADO') {
			this.openModalNotConfirmData();
			return;
		}

		this.dialog.open(ModalAdsComponent, {
			maxWidth: '1000px',
			maxHeight: 'max-content',
			height: 'max-content',
			width: '100%',
			panelClass: 'full-screen-modal'
		});

		/*     if (this.authService.isSeller()) {
      this.authService.hasFreeProduct()
        .subscribe((e: any) => {
          e == 0 && (this.openModalFreeAd())
        })
    } */
	}

	openModalNotConfirmData(): void {
		this.dialog.open(ModalsAlertComponent, {
			maxWidth: '500px',
			minHeight: '200px',
			maxHeight: 'max-content',
			height: 'max-content',
			width: '100%',
			data: { type: 5 },
			panelClass: 'full-screen-modal'
		});
	}

	openModalNotConfirmEmail(): void {
		this.dialog.open(ModalsAlertComponent, {
			maxWidth: '500px',
			minHeight: '200px',
			maxHeight: 'max-content',
			height: 'max-content',
			width: '100%',
			data: { type: 6 },
			panelClass: 'full-screen-modal'
		});
	}
}
