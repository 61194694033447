import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { switchMap, of } from 'rxjs';

export const HomeGuard: CanActivateFn | CanActivateChildFn = () => {
	const authService: AuthService = inject(AuthService);

	// Check the authentication status
	return authService.check().pipe(
		switchMap((authenticated) => {
			// If the user is not authenticated...
			if (!authenticated) {
				// Allow the access
				return of(true);
			}
			// Allow the access
			return of(true);
		})
	);
};
