import { Routes } from '@angular/router';
import { AuthGuard } from '@core/auth/guards/auth.guard';
import { HomeGuard } from '@core/auth/guards/home.guard';

export const appRoutes: Routes = [
	{
		path: '',
		canActivate: [HomeGuard],
		loadComponent: () => import('./modules/home/home.component')
	},
	{
		path: 'verify-email',
		loadComponent: () => import('./shared/components/email-verify/email-verify.component')
	},
	{
		path: 'auth',
		// canActivate: [NoAuthGuard],
		// canActivateChild: [NoAuthGuard],
		// canMatch: [NoAuthGuard],
		loadChildren: () => import('./modules/auth/auth-routes').then((r) => r.AUTH_ROUTES)
	},
	{
		path: 'blog',
		loadComponent: () => import('./modules/blog/blog.component')
	},
	{
		path: 'legals',
		canActivate: [HomeGuard],
		canActivateChild: [HomeGuard],
		loadChildren: () => import('./modules/legals/legals.routes').then((r) => r.LEGALS_ROUTES)
	},
	{
		path: 'notifications',
		canActivate: [AuthGuard],
		loadComponent: () => import('./modules/seller/pages/notification/notification-list.component')
	},
	{
		path: 'seller',
		canActivate: [AuthGuard],
		loadChildren: () => import('./modules/seller/seller.routes').then((r) => r.PROFILE_ROUTES)
	},
	{
		path: 'sale',
		canActivate: [HomeGuard],
		// canActivateChild: [HomeGuard],
		// loadChildren: () => import('./modules/ads/ads.routes').then((r) => r.AD_ROUTES)
		loadChildren: () => import('./ad/ad.routes').then((r) => r.AD_ROUTES)
	},
	{
		path: 'profile',
		canActivate: [HomeGuard],
		canActivateChild: [HomeGuard],
		loadChildren: () => import('./modules/buyer/buyer.routes').then((r) => r.BUYER_ROUTES)
	},
	{
		path: 'support',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		loadChildren: () => import('./modules/support/support.routes').then((r) => r.SUPPORT_ROUTES)
	},
	{
		path: 'alquileres',
		canActivate: [HomeGuard],
		loadChildren: () => import('./modules/rental/rental.routes').then((r) => r.RENTAL_ROUTES)
	},
	{
		path: 'talleres',
		canActivate: [HomeGuard],
		loadChildren: () => import('./modules/garage/garage.routes').then((r) => r.GARAGE_ROUTES)
	},
	{
		path: 'coches-segunda-mano',
		canActivate: [HomeGuard],
		canActivateChild: [HomeGuard],
		loadChildren: () =>
			import('./modules/landings/pages/used-cars/used-cars.routes').then((r) => r.USED_CARS_ROUTES)
	},
	{
		path: ':makeName/coches-segunda-mano',
		canActivate: [HomeGuard],
		canActivateChild: [HomeGuard],
		loadChildren: () =>
			import('./modules/landings/pages/used-cars/used-cars.routes').then((r) => r.USED_CARS_ROUTES)
	},
	{
		path: 'motos-segunda-mano',
		canActivate: [HomeGuard],
		canActivateChild: [HomeGuard],
		loadChildren: () =>
			import('./modules/landings/pages/used-bikes/used-bikes.routes').then(
				(r) => r.USED_MOTOS_ROUTES
			)
	},
	{
		path: ':makeName/motos-segunda-mano',
		canActivate: [HomeGuard],
		canActivateChild: [HomeGuard],
		loadChildren: () =>
			import('./modules/landings/pages/used-bikes/used-bikes.routes').then(
				(r) => r.USED_MOTOS_ROUTES
			)
	},
	{
		path: 'autocaravanas-segunda-mano',
		canActivate: [HomeGuard],
		canActivateChild: [HomeGuard],
		loadChildren: () =>
			import('./modules/landings/pages/used-mobile-home/used-mobile-home.routes').then(
				(r) => r.USED_MOBILE_HOME_ROUTES
			)
	},
	{
		path: ':makeName/autocaravanas-segunda-mano',
		canActivate: [HomeGuard],
		canActivateChild: [HomeGuard],
		loadChildren: () =>
			import('./modules/landings/pages/used-mobile-home/used-mobile-home.routes').then(
				(r) => r.USED_MOBILE_HOME_ROUTES
			)
	},
	{
		path: 'camiones-segunda-mano',
		canActivate: [HomeGuard],
		canActivateChild: [HomeGuard],
		loadChildren: () =>
			import('./modules/landings/pages/used-truck/used-truck.routes').then(
				(r) => r.USED_TRUCKS_ROUTES
			)
	},
	{
		path: ':makeName/camiones-segunda-mano',
		canActivate: [HomeGuard],
		canActivateChild: [HomeGuard],
		loadChildren: () =>
			import('./modules/landings/pages/used-truck/used-truck.routes').then(
				(r) => r.USED_TRUCKS_ROUTES
			)
	},
	{
		path: '403-unauthorized',
		canActivate: [HomeGuard],
		canActivateChild: [HomeGuard],
		loadComponent: () => import('./shared/pages/route-unauthorized/route-unauthorized.component')
	},
	{
		path: 'home',
		redirectTo: ''
	},
	{
		path: '**',
		redirectTo: '404-not-found'
	},
	{
		path: '404-not-found',
		pathMatch: 'full',
		canActivate: [HomeGuard],
		canActivateChild: [HomeGuard],
		loadComponent: () => import('./shared/pages/route-not-found/route-not-found.component')
	}
];
