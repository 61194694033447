import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { NavigationStart, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ModalCookieComponent } from '@shared/components/modal-cookie/modal-cookie.component';
import { ConfigGaService } from '@shared/services/config-ga.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { ObserversModule } from '@angular/cdk/observers';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { CookieService } from '@shared/services/cookies.service';

@Component({
	selector: 'app-cookies',
	standalone: true,
	imports: [MatButtonModule, TranslateModule, ObserversModule, AsyncPipe],
	templateUrl: './cookies.component.html',
	styleUrl: './cookies.component.scss'
})
export class CookiesComponent implements OnInit, AfterViewInit {
	@ViewChild('cookiePolicy') cookiePolicy!: ElementRef;
	@ViewChild('cookieContent') cookieContent!: ElementRef;
	isCookiesShown$: Observable<boolean> = this._cookieService.isCookieShown();
	bannerBottom: string = '50px';
	isHome = location.href.slice(-1) === '/';
	isPhone = true;
	isAppBannerHide = this._ssrCookieService.get('isAppBannerHide') === 'true';

	constructor(
		private readonly _dialog: MatDialog,
		private readonly _ssrCookieService: SsrCookieService,
		private readonly _configGaService: ConfigGaService,
		private readonly _router: Router,
		private readonly _cookieService: CookieService
	) {}

	ngOnInit(): void {
		this._router.events.subscribe({
			next: (value: any) => {
				if (value instanceof NavigationStart) {
					this.isHome = value.url === '/';
				}
			}
		});
	}

	ngAfterViewInit(): void {
		this.isCookiesShown$.subscribe({
			next: (value: boolean) => {
				//Await to render cookie policy section or cookie content section to get correct height
				setTimeout(() => {
					this.bannerBottom = value
						? `${this.cookiePolicy?.nativeElement.offsetHeight + 10}px`
						: `${this.cookieContent?.nativeElement.offsetHeight + 10}px`;
				}, 0);
			}
		});
	}

	cookieElementChanged(event: any): void {
		this.bannerBottom = `${event.target.offsetHeight + 10}px`;
	}

	openCookieContent(): void {
		this._cookieService.setCookieShown(false);
	}

	openConfigCookiesModal(): void {
		this._dialog.open(ModalCookieComponent, {
			data: {
				fase: 2
			}
		});
		this._ssrCookieService.set('showCookieModal', 'false', undefined, '/');
		this._cookieService.setCookieShown(true);
	}

	rejectAllCookies(): void {
		const config = {
			ad_storage: false,
			analytics_storage: false,
			ad_user_data: false,
			ad_personalization: false,
			functionality_storage: false,
			personalization_storage: false,
			advertising: false
		};
		this.deleteCookies();
		this._ssrCookieService.set('consentConfig', JSON.stringify(config), undefined, '/');
		this._configGaService.disableConsent();
		this._ssrCookieService.set('isGaEnabled', 'false', undefined, '/');
		this._ssrCookieService.set('showCookieModal', 'false', undefined, '/');
		this._cookieService.setCookieShown(true);
	}

	acceptAllCookies(): void {
		const config = {
			ad_storage: true,
			analytics_storage: true,
			ad_user_data: true,
			ad_personalization: true,
			functionality_storage: true,
			personalization_storage: true,
			advertising: true
		};
		this.deleteCookies();
		this._ssrCookieService.set('consentConfig', JSON.stringify(config), undefined, '/');
		this._configGaService.enableConsent(config);
		this._ssrCookieService.set('isGaEnabled', 'true', undefined, '/');
		this._ssrCookieService.set('showCookieModal', 'false', undefined, '/');
		this._cookieService.setCookieShown(true);
	}

	goToCookiesPolicy(): void {
		this._router.navigate(['/legals/cookies-policy']);
	}

	deleteCookies(): void {
		this._ssrCookieService.delete('consentConfig');
		this._ssrCookieService.delete('isGaEnabled');
	}
}
