<!-- menu button -->
<button mat-mini-fab [matMenuTriggerFor]="menu" class="!shadow-none" aria-label="menu button">
	<mat-icon class="!icon-size-7 xs:!icon-size-9" svgIcon="mat_outline:menu"></mat-icon>
</button>

<!-- vehicles menu button -->
<mat-menu #menu="matMenu" class="px-2">
	<button mat-menu-item class="font-semibold" [matMenuTriggerFor]="vehicleMenu">
		{{ 'layout.head.vehicle.title' | translate | uppercase }}
	</button>

	<!-- rental button -->
	<button
		mat-menu-item
		class="font-semibold"
		routerLinkActive="!text-red-1"
		[routerLink]="['/alquileres']"
	>
		{{ 'layout.head.rental' | translate | uppercase }}
	</button>

	<!-- garage button -->
	<button
		mat-menu-item
		class="font-semibold"
		routerLinkActive="!text-red-1"
		[routerLink]="['/talleres']"
	>
		{{ 'layout.head.garage' | translate | uppercase }}
	</button>

	<!-- replacement button -->
	<button
		mat-menu-item
		class="font-semibold"
		routerLinkActive="!text-red-1"
		[routerLink]="['/sale/replacement']"
	>
		{{ 'layout.head.replacement' | translate | uppercase }}
	</button>

	<div class="flex flex-col gap-2">
		<hr class="border-b-2" />
		<!-- post button -->
		<button
			appGaClick
			gaClickCategory="header"
			gaClickAction="click"
			gaClickLabel="click-publicar-anuncio"
			class="button-secondary"
			(click)="openModalSelectAds()"
		>
			{{ 'layout.postAd' | translate }}
		</button>

		<!-- login button -->
		<button
			appGaClick
			gaClickCategory="header"
			gaClickAction="click"
			gaClickLabel="click-inicio-sesion"
			class="button-primary"
			*ngIf="!isAuth()"
			routerLink="/auth/sign"
		>
			{{ 'layout.logIn' | translate }}
		</button>
	</div>

	<div class="flex gap-2 justify-between items-center p-2">
		<app-languages (click)="$event.stopPropagation()"></app-languages>
	</div>
</mat-menu>

<!-- vehicles mat menu -->
<mat-menu #vehicleMenu="matMenu" class="!min-w-36">
	<!-- auto redirection tag -->
	<a
		mat-menu-item
		class="font-semibold"
		appGaClick
		gaClickCategory="header"
		gaClickAction="click"
		gaClickLabel="click-filtrar-por-autos"
		routerLink="/coches-segunda-mano"
		routerLinkActive="!text-red-1"
	>
		{{ 'layout.head.vehicle.auto' | translate | uppercase }}
	</a>

	<!-- moto redirection tag -->
	<a
		mat-menu-item
		class="font-semibold"
		appGaClick
		gaClickCategory="header"
		gaClickAction="click"
		gaClickLabel="click-filtrar-por-motos"
		routerLink="/motos-segunda-mano"
		routerLinkActive="!text-red-1"
	>
		{{ 'layout.head.vehicle.moto' | translate | uppercase }}
	</a>

	<!-- truck redirection tag -->
	<a
		mat-menu-item
		class="font-semibold"
		appGaClick
		gaClickCategory="header"
		gaClickAction="click"
		gaClickLabel="click-filtrar-por-camiones"
		routerLink="/camiones-segunda-mano"
		routerLinkActive="!text-red-1"
	>
		{{ 'layout.head.vehicle.truck' | translate | uppercase }}
	</a>

	<!-- mobile home redirection tag -->
	<a
		mat-menu-item
		class="font-semibold"
		appGaClick
		gaClickCategory="header"
		gaClickAction="click"
		gaClickLabel="click-filtrar-por-caravanas"
		routerLink="/autocaravanas-segunda-mano"
		routerLinkActive="!text-red-1"
	>
		{{ 'layout.head.vehicle.mobileHome' | translate | uppercase }}
	</a>
</mat-menu>
