<div class="flex gap-3 items-center">
	<!-- vehicles menu button -->
	<button class="flex items-center hover:text-red-1" [matMenuTriggerFor]="vehicleMenu">
		<span class="font-semibold">
			{{ 'layout.head.vehicle.title' | translate | uppercase }}
		</span>
		<mat-icon class="text-red-1" svgIcon="mat_outline:expand_more"></mat-icon>
	</button>

	<!-- rental button -->
	<button
		class="font-semibold hover:text-red-1"
		routerLinkActive="!text-red-1"
		[routerLink]="['/alquileres']"
	>
		{{ 'layout.head.rental' | translate | uppercase }}
	</button>

	<!-- garage button -->
	<button
		class="font-semibold hover:text-red-1"
		routerLinkActive="!text-red-1"
		[routerLink]="['/talleres']"
	>
		{{ 'layout.head.garage' | translate | uppercase }}
	</button>

	<!-- replacement button -->
	<button
		class="font-semibold hover:text-red-1"
		routerLinkActive="!text-red-1"
		[routerLink]="['/sale/replacement']"
	>
		{{ 'layout.head.replacement' | translate | uppercase }}
	</button>

	<!-- post button -->
	<button
		appGaClick
		gaClickCategory="header"
		gaClickAction="click"
		gaClickLabel="click-publicar-anuncio"
		class="button-secondary"
		(click)="openModalSelectAds()"
	>
		{{ 'layout.postAd' | translate }}
	</button>

	<!-- login button -->
	<button
		appGaClick
		gaClickCategory="header"
		gaClickAction="click"
		gaClickLabel="click-inicio-sesion"
		class="button-primary"
		*ngIf="!isAuth()"
		routerLink="/auth/sign"
	>
		{{ 'layout.logIn' | translate }}
	</button>
</div>

<!-- vehicles mat menu -->
<mat-menu #vehicleMenu="matMenu" yPosition="below">
	<!-- auto redirection tag -->
	<a
		mat-menu-item
		class="font-semibold"
		appGaClick
		gaClickCategory="header"
		gaClickAction="click"
		gaClickLabel="click-filtrar-por-autos"
		routerLink="/coches-segunda-mano"
		routerLinkActive="!text-red-1"
	>
		{{ 'layout.head.vehicle.auto' | translate | uppercase }}
	</a>

	<!-- moto redirection tag -->
	<a
		mat-menu-item
		class="font-semibold"
		appGaClick
		gaClickCategory="header"
		gaClickAction="click"
		gaClickLabel="click-filtrar-por-motos"
		routerLink="/motos-segunda-mano"
		routerLinkActive="!text-red-1"
	>
		{{ 'layout.head.vehicle.moto' | translate | uppercase }}
	</a>

	<!-- truck redirection tag -->
	<a
		mat-menu-item
		class="font-semibold"
		appGaClick
		gaClickCategory="header"
		gaClickAction="click"
		gaClickLabel="click-filtrar-por-camiones"
		routerLink="/camiones-segunda-mano"
		routerLinkActive="!text-red-1"
	>
		{{ 'layout.head.vehicle.truck' | translate | uppercase }}
	</a>

	<!-- mobile home redirection tag -->
	<a
		mat-menu-item
		class="font-semibold"
		appGaClick
		gaClickCategory="header"
		gaClickAction="click"
		gaClickLabel="click-filtrar-por-caravanas"
		routerLink="/autocaravanas-segunda-mano"
		routerLinkActive="!text-red-1"
	>
		{{ 'layout.head.vehicle.mobileHome' | translate | uppercase }}
	</a>
</mat-menu>
