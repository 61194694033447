<div class="bg-[#F2F2F2] w-full max-sm:pl-2 pr-2 flex">
	<div class="flex gap-x-3 w-full items-center justify-between">
		<div
			class="flex flex-1 gap-2 sm:gap-6 w-full items-center sm:bg-white sm:rounded-r-full py-3 sm:pr-12 pl-2"
		>
			<!-- Close button-->
			<mat-icon
				class="icon-size-7 cursor-pointer hover:bg-gray-50 rounded-full"
				svgIcon="mat_outline:close"
				(click)="hideAppBanner()"
			></mat-icon>

			<!-- Text -->
			<div class="flex flex-col flex-grow">
				<span class="font-bold text-lg">
					{{ 'modal.appStoreBanner.text3' | translate }}

					<span class="text-[#D91218] sm:text-red-1">
						{{ 'modal.appStoreBanner.autosmotos' | translate }}
					</span>
				</span>

				<span class="font-bold max-sm:hidden"> {{ 'modal.appStoreBanner.text2' | translate }}</span>
			</div>

			<!-- App img -->
			<img
				class="h-[79px] w-[46px] max-xs:hidden"
				width="69"
				height="125"
				loading="eager"
				src="/assets/images/banner/app/get_app_phone_69w.webp"
				alt="phone-app-img"
			/>
		</div>

		<!-- Download now button -->
		<button
			mat-raised-button
			color="primary"
			class="!h-fit xm:!w-[10.5rem] sm:!ml-4 !p-2"
			(click)="downloadApp()"
		>
			<span class="xs:hidden">{{ 'modal.appStoreBanner.download' | translate }} </span>
			<span class="max-xs:hidden">{{ 'modal.appStoreBanner.downloadNow' | translate }} </span>
		</button>
	</div>
</div>
